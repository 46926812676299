import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostTemplateDetails from '../components/PostTemplateDetails'
import SEO from '../components/SEO'

class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { slug } = post.fields
    const { title, description, featuredImage, date } = post.frontmatter
    const featuredImagePath =
      featuredImage && featuredImage.childImageSharp.fixed.src

    return (
      <Layout>
        <div>
          <SEO
            title={title}
            description={description}
            image={featuredImagePath}
            pathname={slug}
            article={{ publishedTime: date }}
          />
          <PostTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          twitter
        }
        disqusShortname
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        tags
        date
        featuredImage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        description
      }
    }
  }
`
